import UploadForm from "../../UploadForm/UploadForm"
import OpenTextBox from "../../OpenTextBox/OpenTextBox"
import { useState, useEffect } from 'react'
import { initiateAssessment } from "../../../services/CloudMigrationAdvisor/ContextAssessment"
import './InformationGathering.css'
import UploadFormCMA from "../../UploadForm/UploadFormCMA"

const InformationGathering = ({
    initiateUpload,
    projectId,
    assessmentId
}) => {
    const [textAreaValue, setTextAreaValue] = useState("")
    const [rawFormData, setRawFormData] = useState([])

    const addFile = (file, formDataFileNamfile) => {
        setRawFormData([...rawFormData, [formDataFileNamfile, file]])
    }

    const uploadFiles = async () => {
        try {
            const completeFormData = new FormData()
            rawFormData.map(singelFile => completeFormData.append(singelFile[0], singelFile[1]))
            const response = await initiateAssessment(projectId, assessmentId, completeFormData)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const removeFormData = (formDataKey) => {
        const filterd = rawFormData.filter(rFormData => rFormData[0] != formDataKey)
        setRawFormData(filterd)
    }

    useEffect(() => {
        if (initiateUpload > 0) uploadFiles()
    }, [initiateUpload])

    return (
        <div className="information-gathering-wrapper">
            <UploadFormCMA
                title="Company Information"
                info="Include here all the documents useful to understand the general information about the organization"
                confirmUpload={false}
                handleUpload={(file) => addFile(file, "general_info_file")}
                handleRemoveFiles={() => removeFormData("general_info_file")}
            />
            <UploadFormCMA
                title="Initiative and scope objectives"
                info="Please include here all the documents useful to understand thepurpose of the cloud migration and provide useful information about the goalsto be achieved"
                confirmUpload={false}
                handleUpload={(file) => addFile(file, "scope_and_objectives_file")}
                handleRemoveFiles={() => removeFormData("scope_and_objectives_file")}
            />
            <UploadFormCMA
                title="Infrastructure Assessment"
                info="Include here all the documents that contain information about your IT infrastructure. For subsequent analysis, you need to download the template and fill it in"
                confirmUpload={false}
                downloadable={true}
                downloadUrl={`${process.env.REACT_APP_SERVER_URL}/templates/download/Infrastructure_Template/`}
                handleUpload={(file) => addFile(file, "infrastructure_text_file")}
                UploadTepmlate={true}
                handleUploadTemplate={(file) => addFile(file, "infrastructure_excel_file")}
                handleRemoveFiles={() => { removeFormData("infrastructure_text_file"); removeFormData("infrastructure_excel_file") }}
            />
            <UploadFormCMA
                title="Application Assessment"
                info="Include here all the documents that contain information about your application. For subsequent analysis, you need to download the template and fill it in"
                confirmUpload={false}
                downloadable={true}
                handleUpload={(file) => addFile(file, "application_text_file")}
                UploadTepmlate={true}
                handleUploadTemplate={(file) => addFile(file, "application_excel_file")}
                downloadUrl={`${process.env.REACT_APP_SERVER_URL}/templates/download/Application_Template/`}
                handleRemoveFiles={() => { removeFormData("application_excel_file"); removeFormData("application_text_file") }}
            />
            <UploadFormCMA
                title="Technical Documents"
                info="Please include here all the documents useful to understand thepurpose of the cloud migration and provide useful information about the goalsto be achieved"
                confirmUpload={false}
                handleUpload={() => { }}
                handleRemoveFiles={() => { }}
            />
            <UploadFormCMA
                title="Organization & Process Documents"
                info="Include here all the documents that you think are useful to understand how a possible migration to the cloud can impact the organization and internal processes"
                confirmUpload={false}
                handleUpload={() => { }}
                handleRemoveFiles={() => { }}
            />
            <UploadFormCMA
                title="Other Information"
                info="Please include here all the documents useful to understand thepurpose of the cloud migration and provide useful information about the goalsto be achieved"
                confirmUpload={false}
                handleUpload={() => { }}
                handleRemoveFiles={() => { }}
            />
            <OpenTextBox
                subtitle="Enter any other information in text form in this field"
                value={textAreaValue}
                setValue={setTextAreaValue}
            />
        </div>
    )
}

export default InformationGathering