import React, { useState, useEffect } from "react";
import "./chatPage.css";
import { getChatbotHistory,sendChatbotQuestion } from "../../services/chatService";
import { useMyGlobalContext } from "../../global/globalContext"
import { useNavigate } from "react-router-dom"
import ChatWithRobot from "../../components/Chat/ChatWithRobot";

const ChatPage = () => {
  const { globalState } = useMyGlobalContext()
  const navigate = useNavigate()
  useEffect(() => {
    if(!globalState.chatId){
      navigate("/cloud-suitability-assessment")
      return
    }
    // fetchChatHistory();
  }, []);

  const handleGoBack = () => {
    navigate("/cloud-suitability-assessment")
  };

  return (
    <div className="chat-container">
      <ChatWithRobot
        fetchChatHistory={getChatbotHistory}
        sendChatbotQuestion={sendChatbotQuestion}
        regen={false}
        responseTitle="DKN"
      />
    </div>
  );
};

export default ChatPage;
