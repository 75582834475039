import { API } from '../api'

const getContextAssessment = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/`);
const putContextAssessment = async (projectId, assessmentId, data) => API().put(`/project/${projectId}/assessment/${assessmentId}/`, data);

const getContextQuestions = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/question/`);
const deleteContextQuestion = async (projectId, assessmentId, questionId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/question/${questionId}/`);
const putContextQuestion = async (projectId, assessmentId, questionId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/question/${questionId}/`, body);
const generateContextQuestion = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/question/generate/`)

const generateContextSummary = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/assessment-result/context-resume/generate/`)
const getSummary = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/assessment-result/`);
const putSummary = async (projectId, assessmentId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/assessment-result/`, body);

const uploadContext = (projectId, assessmentId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/input/`, body, { headers: { 'Content-Type': 'multipart/form-data', } });

const initiateAssessment = (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/fullassessment/`, body, { headers: { 'Content-Type': 'multipart/form-data', } });

const getContextFindings = (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/`)
const putContextFindings = (projectId, assessmentId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/`, body)

const generateFinalSummary = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/finalsummary/generate`)

const deleteCompanyContextFinding = (projectId, assessmentId, contextFindingId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/${contextFindingId}/`)
const deleteInfastractureContextFinding = (projectId, assessmentId, contextFindingId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/infrastructurecriticalpoint/${contextFindingId}/`)
const deleteApplicationContextFinding = (projectId, assessmentId, contextFindingId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/application/criticalpoint/${contextFindingId}/`)

const updateCompanyContextFinding = (projectId, assessmentId, contextFindingId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/${contextFindingId}/`, body)
const updateInfastractureContextFinding = (projectId, assessmentId, contextFindingId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/infrastructurecriticalpoint/${contextFindingId}/`, body)
const updateApplicationContextFinding = (projectId, assessmentId, contextFindingId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/application/criticalpoint/${contextFindingId}/`, body)

const generateCompanyKeyFinding = (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/generate/`)
const generateApplicationKeyFinding = (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/application/criticalpoint/generate/`)

const deleteAllCompanyContextFindings = (projectId, assessmentId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/delete/`)
const deleteAllApplicationContextFindings = (projectId, assessmentId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/contextcriticalpoint/delete/`)


const getStatus = (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/status/`)

export {
    getContextAssessment,
    putContextAssessment,
    getContextQuestions,
    deleteContextQuestion,
    putContextQuestion,
    deleteAllApplicationContextFindings,
    generateContextQuestion,
    generateContextSummary,
    putSummary,
    getSummary,
    uploadContext,
    initiateAssessment,
    getContextFindings,
    putContextFindings,
    deleteCompanyContextFinding,
    deleteInfastractureContextFinding,
    deleteApplicationContextFinding,
    updateInfastractureContextFinding,
    updateApplicationContextFinding,
    deleteAllCompanyContextFindings,
    updateCompanyContextFinding,
    generateApplicationKeyFinding,
    getStatus,
    generateFinalSummary,
    generateCompanyKeyFinding

};