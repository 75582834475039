import { API } from '../api'

const putInfraContext = async (projectId, assessmentId, data) => API().put(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/`, data);
const getInfraContext = async (projectId, assessmentId, data) => API().get(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/`, data);

const generateInfraQuestions = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/question/generate/`);

const getInfraCluster = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/cluster/`);
const postInfraCluster = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/cluster/`, body);

const getInfraServer = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/server/`);
const postInfraServer = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/server/`, body);

const getInfraVirtualizationCluster = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/`);
const postInfraVirtualizationCluster = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/`, body);
const updateInfraVirtualizationCluster = async (projectId, assessmentId) => API().put(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/updatestats/`);

const generateInfraContextQuestions = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/question/generate/`);

const uploadInfraServers = (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/server/upload/`, body, { headers: { 'Content-Type': 'multipart/form-data', } });
const uploadInfraSoftware = (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/upload/`, body, { headers: { 'Content-Type': 'multipart/form-data', } });
const uploadInfraContext = (projectId, assessmentId, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/input/`, body, { headers: { 'Content-Type': 'multipart/form-data', } });

const getInfraSoftware = (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/`);
const deleteSingleInfraSoftware = (projectId, assessmentId, id) => API().delete(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${id}/`);
const putSingleInfraSoftware = (projectId, assessmentId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${id}/`, body);
const postSingleInfraSoftware = (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/`, body);

const generateSoftwareObsol = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/situationconsideration/generate/`);
const endOfLifeGenerate = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/eol`);


const generateInfrastractureSummary = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/resume/generate/`)
const generateInfrastracturePoints = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecriticalpoint/generate/`)

const getInfraPoints = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/infrastructurecriticalpoint/`)
const getVirtualClusters = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/`)

const deleteVirtualCluster = async (projectId, assessmentId, id) => API().delete(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${id}/`)
const putVirtualCluster = async (projectId, assessmentId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${id}/`, body)
const postVirtualCluster = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/`, body)

const getClusters = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/cluster/`)
const deleteCluster = async (projectId, assessmentId, id) => API().delete(`/project/${projectId}/assessment/${assessmentId}/cluster/${id}/`)
const putCluster = async (projectId, assessmentId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/cluster/${id}/`, body)
const postCluster = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/cluster/`, body)

const getServers = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/server/`)
const deleteServer = async (projectId, assessmentId, id) => API().delete(`/project/${projectId}/assessment/${assessmentId}/server/${id}/`)
const putServer = async (projectId, assessmentId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/server/${id}/`, body)
const postServer = async (projectId, assessmentId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/server/`, body)

const getHosts = async (projectId, assessmentId, VCId) => API().get(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${VCId}/physicalhost/`)
const deleteHost = async (projectId, assessmentId, VCId, id) => API().delete(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${VCId}/physicalhost/${id}/`)
const putHost = async (projectId, assessmentId, VCId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${VCId}/physicalhost/${id}/`, body)
const postHost = async (projectId, assessmentId, VCId, body) => API().post(`/project/${projectId}/assessment/${assessmentId}/virtualizationcluster/${VCId}/physicalhost/`, body)

const generateInfraFault = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/saturationfaulttolerance/generate/`);
const generateHostObsolescence = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/benchmarkanalysis/generate/`);

const generateMigrateCriticality = async (projectId, assessmentId, softwareId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${softwareId}/migrationcriticality/`);
const getHostObsolescence = async (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/physicalhost/obsolescence/`);

const deleteAllApplicationContextFindings = (projectId, assessmentId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/infrastructurecriticalpoint/delete/`)

const generateFaultAnalysis = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/saturationfaulttolerance/generate/`);

const generateHardwareObsolescence = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructurecontext/benchmarkanalysis/generate/`);
const generateSoftwareDistribution = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/situationconsideration/generate/`);

const deleteSoftware = async (projectId, assessmentId, softwareId) => API().delete(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${softwareId}/`);
const generateEndOfLife = async (projectId, assessmentId, softwareId) => API().post(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${softwareId}/eol/`);


const putInfraSoftware = async (projectId, assessmentId, id, body) => API().put(`/project/${projectId}/assessment/${assessmentId}/infrastructuresoftware/${id}/`, body)


export {
    deleteSoftware,
    putInfraSoftware,
    generateEndOfLife,
    uploadInfraServers,
    putInfraContext,
    getInfraContext,
    generateFaultAnalysis,
    generateInfraQuestions,
    generateSoftwareDistribution,
    getInfraCluster,
    generateHardwareObsolescence,
    postInfraCluster,
    getInfraServer,
    postInfraServer,
    deleteAllApplicationContextFindings,
    getInfraVirtualizationCluster,
    uploadInfraSoftware,
    getInfraSoftware,
    deleteSingleInfraSoftware,
    putSingleInfraSoftware,
    postSingleInfraSoftware,
    generateSoftwareObsol,
    endOfLifeGenerate,
    generateInfrastractureSummary,
    generateInfrastracturePoints,
    getInfraPoints,
    updateInfraVirtualizationCluster,
    getVirtualClusters,
    deleteVirtualCluster,
    putVirtualCluster,
    postVirtualCluster,
    getClusters,
    deleteCluster,
    putCluster,
    postCluster,
    getServers,
    deleteServer,
    putServer,
    postServer,
    getHosts,
    deleteHost,
    putHost,
    postHost,
    generateInfraFault,
    uploadInfraContext,
    generateHostObsolescence,
    generateMigrateCriticality,
    getHostObsolescence
};