import { useState, useEffect } from 'react'
import InformationGathering from '../../../components/CMA/InformationGathering/InformationGathering'
import { generateContextSummary, deleteAllCompanyContextFindings, generateCompanyKeyFinding, putContextFindings, updateApplicationContextFinding, updateInfastractureContextFinding } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { updateCompanyContextFinding } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import CompanyProfile from '../../../components/CMA/CompanyProfile/CompanyProfile'
import { deleteApplicationContextFinding, deleteCompanyContextFinding, deleteInfastractureContextFinding } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import InfrastructureAssessment from '../../../components/CMA/InfrastructureAssessment/InfrastructureAssessment'
import { generateFinalSummary } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { generateApplicationKeyFinding } from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import { generateInfrastracturePoints, deleteAllApplicationContextFindings } from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'
import { generateFaultAnalysis, generateHardwareObsolescence, generateSoftwareDistribution } from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'
import {
    putSummary,
    getSummary,
    getContextFindings,
    getStatus
} from '../../../services/CloudMigrationAdvisor/ContextAssessment'
import {
    getInfraPoints,
    getVirtualClusters,
    getHosts,
    getInfraSoftware,
    getInfraServer,
    generateInfrastractureSummary
} from '../../../services/CloudMigrationAdvisor/InfrastructureAssessment'
import {
    getApplications,
    getApplicationCard,
    getApplicationsFindings,
    generateApplicationSummary
} from '../../../services/CloudMigrationAdvisor/applicationsAssessments'
import ApplicationAssessment from '../../../components/CMA/ApplicationAssessment/ApplicationAssessment'
import ChatWithRobotButton from '../../../components/Chat/ChatWithRobotButton'
import CMASummary from '../../../components/CMA/CMASummary/CMASummary'
import "./AdvisorWrapper.css"
import {
    getChats,
    createChat,
    getChatHistory,
    questionChat
} from '../../../services/CloudMigrationAdvisor/chat'
import { useMyGlobalContext } from '../../../global/globalContext'
import { useNavigate } from 'react-router-dom';

const AdvisorWrapper = () => {
    const { globalState } = useMyGlobalContext()
    const [selectedItem, setSelectedItem] = useState(0)
    const [expanded, setExpanded] = useState(true)
    const [companySummary, setCompanySummary] = useState("")
    const [companyFindings, setCompanyFindings] = useState("")
    const [cMASummary, setCMASummary] = useState("")
    const [cMAFindings, setCMAFindings] = useState("")
    const [infraSummary, setInfraSummary] = useState("")
    const [infraFindings, setInfraFindings] = useState("")
    const [initiateUpload, setInitiateUpload] = useState(0)
    const [currentChatId, setCurrentChatId] = useState()
    const [infraVisualClusters, setInfraVisualClusters] = useState([])
    const [infraFault, setInfraFault] = useState([])
    const [infraFaultMessage, setInfraFaultMessage] = useState("")
    const [infraHardware, setInfraHardware] = useState([])
    const [infraHardwareMessage, setInfraHardwareMessage] = useState("")
    const [softwares, setSoftwares] = useState([])
    const [servers, setServers] = useState([])
    const [softwareMessage, setSoftwareMessage] = useState("")
    const [infraClusters, setInfraClusters] = useState("")
    const [applicationSummary, setApplicationSummary] = useState("")
    const [applicationFindings, setApplicationFindings] = useState("")
    const [applicationCards, setApplicationCards] = useState([])
    const [applicationsGeneral, setApplicationsGeneral] = useState([])
    const [statuses, setStatuses] = useState([1, 1, 1, 1, 1])

    const [companyPoints, setCompanyPoints] = useState([])

    const saveCompanyProfile = async () => {
        try {
            const body = {
                ContextResume: companySummary
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }
    const saveInfaAssessment = async () => {
        try {
            const body = {
                ITInfrastructureResume: infraSummary
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }
    const saveApplicationSummary = async () => {
        try {
            const body = {
                ITApplicationResume: applicationSummary
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }
    const saveFaultMessage = async () => {
        try {
            const body = {
                FaultTolerance_Analysis_Consideration: infraFaultMessage
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }
    const saveHardwareMessage = async () => {
        try {
            const body = {
                HostObsolence_Analysis_Consideration: infraHardwareMessage
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }

    const saveSoftwareMessage = async () => {
        try {
            const body = {
                SoftwareObsolence_Analysis_Consideration: softwareMessage
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }
    const saveFinalSummary = async () => {
        try {
            const body = {
                FinalSummary: cMASummary
            }
            const response = await putSummary(globalState.projectId, globalState.assessmentId, body)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }

    const generateNewCompanySummary = async () => {
        try {

            const response = await generateContextSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }



    const generateNewInfrastructureSummary = async () => {
        try {

            const response = await generateInfrastractureSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }





    const generateNewApplicationSummary = async () => {
        try {

            const response = await generateApplicationSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }


    const generateNewFinalSummary = async () => {
        try {

            const response = await generateFinalSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }


    const generateNewFaultAnalysis = async () => {
        try {

            const response = await generateFaultAnalysis(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }


    const generateNewHardwareObsolescence = async () => {
        try {

            const response = await generateHardwareObsolescence(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }


    const generateNewSoftwareDistribution = async () => {
        try {

            const response = await generateSoftwareDistribution(globalState.projectId, globalState.assessmentId)
            const data = response.data

        } catch (error) {
            console.log(error)
        }
    }






    const navigate = useNavigate()
    const data = [
        {
            title: "Information Gathering",
            status: statuses[0],
            component: <InformationGathering initiateUpload={initiateUpload} projectId={globalState.projectId} assessmentId={globalState.assessmentId} />
        },
        {
            title: "Company Profile",
            status: statuses[1],
            component: <CompanyProfile
                summary={companySummary}
                setSummary={setCompanySummary}
                keyFindings={companyFindings}
                setKeyFindings={setCompanyFindings}
                points={companyPoints}
                setPoints={setCompanyPoints}
                update={saveCompanyProfile}
                deleteContextFinding={deleteCompanyContextFinding}
                updateKeyFinding={updateCompanyContextFinding}
                generateNewSummary={generateNewCompanySummary}
                generateNewKeyFinding={generateCompanyKeyFinding}
                deleteAllKeyFindings={deleteAllCompanyContextFindings}
            />
        },
        {
            title: "Infrastructure Assessment",
            status: statuses[2],
            component: <InfrastructureAssessment
                summary={infraSummary}
                setSummary={setInfraSummary}
                keyFindings={infraFindings}
                setPoints={setInfraFindings}
                visualClusters={infraVisualClusters}
                faultToleranceAnalysis={infraFault}
                faultToleranceMessage={infraFaultMessage}
                setFaultToleranceMessage={setInfraFaultMessage}
                hardwareObsolence={infraHardware}
                hardwareObsolenceMessage={infraHardwareMessage}
                setHardwareObsolenceMessage={setInfraHardwareMessage}
                softwares={softwares}
                servers={servers}
                infraClusters={infraClusters}
                softwareMessage={softwareMessage}
                setSoftwareMessage={setSoftwareMessage}
                projectId={globalState.projectId}
                assessmentId={globalState.assessmentId}
                update={saveInfaAssessment}
                generateNewSummary={generateNewInfrastructureSummary}
                deleteContextFinding={deleteInfastractureContextFinding}
                updateKeyFinding={updateInfastractureContextFinding}
                updateFaultMessage={saveFaultMessage}
                updateHardwareMessage={saveHardwareMessage}
                updateSoftwareMessage={saveSoftwareMessage}
                generateNewKeyFinding={generateInfrastracturePoints}
                deleteAllKeyFindings={deleteAllApplicationContextFindings}
                faultToleranceGenerate={generateNewFaultAnalysis}
                hardwareObsolenceGenerate={generateNewHardwareObsolescence}
                softwareDistributionGenerate={generateNewSoftwareDistribution}
            />
        },
        {
            title: "Application Assessment",
            status: statuses[3],
            component: <ApplicationAssessment
                summary={applicationSummary}
                setSummary={setApplicationSummary}
                keyFindings={applicationFindings}
                setPoints={setApplicationFindings}
                applicationDetails={applicationCards}
                applications={applicationsGeneral}
                update={saveApplicationSummary}
                deleteContextFinding={deleteApplicationContextFinding}
                updateKeyFinding={updateApplicationContextFinding}
                generateNewSummary={generateNewApplicationSummary}
                generateNewKeyFinding={generateApplicationKeyFinding}
            // deleteAllKeyFindings={ }


            />
        },
        {
            title: "Final Summary",
            status: statuses[4],
            component: <CMASummary
                summary={cMASummary}
                setSummary={setCMASummary}
                keyFindings={cMAFindings}
                setKeyFindings={setCMAFindings}
                update={saveFinalSummary}
                generateNewSummary={generateNewFinalSummary}

            />
        }
    ]

    useEffect(() => {
        if (!globalState.projectId || !globalState.assessmentId) {
            navigate("/it-assessment")
        }
    }, [])

    const handleNextClick = () => {
        if (selectedItem < data.length - 1) {
            if (selectedItem == 0) {
                setInitiateUpload(initiateUpload + 1)
            } else {
                setSelectedItem(selectedItem + 1)
            }
        }
    }

    const initSummaries = async () => {
        try {
            const response = await getSummary(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setCompanySummary(data.ContextResume)
            setInfraSummary(data.ITInfrastructureResume)
            setInfraFaultMessage(data.FaultTolerance_Analysis_Consideration)
            setInfraHardwareMessage(data.HostObsolence_Analysis_Consideration)
            setSoftwareMessage(data.SoftwareObsolence_Analysis_Consideration)
            setApplicationSummary(data.ITApplicationResume)
            setCMASummary(data.FinalSummary)
        } catch (error) {
            console.log(error)
        }
    }

    const initFindings = async () => {
        try {
            const response = await getContextFindings(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setCompanyPoints(data)
            const infraResponse = await getInfraPoints(globalState.projectId, globalState.assessmentId)
            const infraData = infraResponse.data
            setInfraFindings(infraData)
            const applicationResponse = await getApplicationsFindings(globalState.projectId, globalState.assessmentId)
            const applicationData = applicationResponse.data
            setApplicationFindings(applicationData)
        } catch (error) {
            console.log(error)
        }
    }

    const initChat = async () => {
        try {
            const response = await getChats(globalState.projectId, globalState.assessmentId)
            const chats = response.data.chat_list
            if (chats.length == 0) {
                const body = {
                    context: "Make everything better",
                    chat_type: "standard"
                }
                const response = await createChat(globalState.projectId, globalState.assessmentId, body)
            } else {
                setCurrentChatId(chats[chats.length - 1].chat_id)
            }
        } catch (error) {

        }
    }

    const initInfra = async () => {
        try {
            const response = await getVirtualClusters(globalState.projectId, globalState.assessmentId)
            const vClusters = response.data.map(vCluster => ([
                vCluster.Name, parseFloat(vCluster.vCPU_to_pCore_Ratio).toFixed(2), parseFloat(vCluster.vRAM_to_pRAM_Ratio).toFixed(2), vCluster.Number_of_Virtual_Machines
            ]))
            setInfraVisualClusters(vClusters)
            const infraFaultTolerance = response.data.map(fault => ([
                fault.Name, fault.Number_of_Physical_Hosts, fault.Number_of_Max_Concurrent_Faults
            ]))
            const vClusters2 = response.data.map(vCluster => ([
                vCluster.id, vCluster.Name, vCluster.Type, vCluster.Number_of_Physical_Hosts, vCluster.Number_of_Virtual_Machines, vCluster.Number_of_PhysicalCore,
                vCluster.Number_of_VirtualCPU, parseFloat(vCluster.vCPU_to_pCore_Ratio).toFixed(2), vCluster.PhysicalRAM, vCluster.VirtualRAM,
                parseFloat(vCluster.vRAM_to_pRAM_Ratio).toFixed(2), vCluster.Number_of_Max_Concurrent_Faults
            ]))
            setInfraClusters(vClusters2)
            setInfraFault(infraFaultTolerance)
            const clustersIds = response.data.map(cluster => cluster.id)
            let i = 0;
            let hostsArray = []
            for (i; i < clustersIds.length; i += 1) {
                const res = await getHosts(globalState.projectId, globalState.assessmentId, clustersIds[i])
                const hostOfACluster = res.data.map(hosts => ([
                    response.data[i].Name, hosts.Model, hosts.CPUModel, hosts.SPEC_CPU_2017_Speed_Integer, hosts.SPEC_CPU_2017_Rate_Integer
                ]))
                hostsArray = [...hostsArray, ...hostOfACluster]
            }
            setInfraHardware(hostsArray)
        } catch (error) {
            console.log(error)
        }
    }

    const initApplications = async () => {
        try {
            const response = await getApplications(globalState.projectId, globalState.assessmentId)
            const data = response.data
            let i = 0
            let applicationCardsD = []
            let applicationGeneralTemp = []
            for (i; i < data.length; i += 1) {
                const cardData = await getApplicationCard(globalState.projectId, globalState.assessmentId, data[i].id)
                const cardFData = cardData.data
                applicationGeneralTemp.push([
                    data[i].Name, data[i].Description, data[i].FunctionalArea, data[i].UserNumber
                ])
                applicationCardsD.push(
                    {
                        title: cardFData.Name,
                        description: cardFData.Description,
                        applicationCriticality: cardFData.ApplicationCriticality,
                        applicationCriticalityText: cardFData.ApplicationCriticalityReasoning,
                        criticality: cardFData.TrafficDataManagementCriticality,
                        criticalityText: cardFData.TrafficDataManagementCriticalityReasoning,
                        quality: cardFData.CodeSoftwareQualityCriticality,
                        qualityText: cardFData.CodeSoftwareQualityCriticalityReasoning,
                        integrationAnalysis: cardFData.IntegrationsAnalysis,
                        information: [
                            {
                                label: "ARCHITECTURE TYPE",
                                value: cardFData.Architecture
                            },
                            {
                                label: "CUSTOMIZATION DEGREE",
                                value: cardFData.CustomizationDegree
                            },
                            {
                                label: "FUNCTIONAL AREA",
                                value: cardFData.FunctionalArea
                            },
                            {
                                label: "N. SERVERS",
                                value: cardFData.NumberOfServers
                            },
                            {
                                label: "DESCRIPTION",
                                value: cardFData.Description
                            },
                            {
                                label: "INTEGRATION",
                                value: cardFData.IntegrationsAnalysis
                            }
                        ],
                        stack: cardFData.SoftwareSupportLevel,
                        stackText: cardFData.SoftwareSupportLevelAnalysis,
                        itegration: {
                            internal: ["app1", "app2", "app3"],
                            external: ["app1", "app2", "app3"],
                            numberOfIntegration: 3
                        },
                        migrationStrategy: cardFData.MigrationStrategy,
                        migrationStrategyText: cardFData.MigrationStrategyReasoning
                    }
                )
            }
            setApplicationCards(applicationCardsD)
            setApplicationsGeneral(applicationGeneralTemp)
        } catch (error) {
            console.log(error)
        }
    }

    const initSoftware = async () => {
        try {
            const response = await getInfraSoftware(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setSoftwares(data.map(software => ([
                software.id, software.Name, software.Version, software.Description, software.Type, software.Number_of_Installations, software.End_of_Support_Date, software.Migration_criticality
            ])
            ))
        } catch (error) {
            console.log(error)
        }
    }
    const initServers = async () => {
        try {
            const response = await getInfraServer(globalState.projectId, globalState.assessmentId)
            const data = response.data
            setServers(data.map(server => ([
                server.id, server.Name, server.Operating_System, server.CPU, server.RAM, server.Storage, server.Type, server.VirtualizationCluster, server.Cluster
            ])
            ))
        } catch (error) {
            console.log(error)
        }
    }


    const getStatuses = async () => {
        try {
            const response = await getStatus(globalState.projectId, globalState.assessmentId)
            const data = response.data
            if (statuses[1] == 1) {
                if (data["Company Profile"] == "Completed") {
                    initSummaries()
                    initFindings()
                }
                if (statuses[2] == 1) {
                    if (data["Infrastructure Assessment"] == "Completed") {
                        initInfra()
                        initServers()
                    }
                }
                if (statuses[3] == 1) {
                    if (data["Application Assessment"] == "Completed") {
                        initSoftware()
                    }
                }
                setStatuses([
                    1,
                    data["Company Profile"] == "Completed" ? 3 : 1,
                    data["Infrastructure Assessment"] == "Completed" ? 3 : 1,
                    data["Application Assessment"] == "Completed" ? 3 : 1,
                    data["Final Summary"] == "Completed" ? 3 : 1,
                ])
                if (statuses[1] != 3 || statuses[2] != 3 || statuses[3] != 3 || statuses[4] != 3) {
                    setTimeout(() => getStatuses(), 20000)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const initAll = async () => {
        initSummaries()
        initFindings()
        initChat()
        initInfra()
        initSoftware()
        initApplications()
    }

    useEffect(() => {
        initSummaries()
        initFindings()
        initChat()
        initInfra()
        initServers()
        initSoftware()
        initApplications()
        // getStatuses()
    }, [])

    const getColor = (status) => {
        if (status == 1) {
            return "#808080"
        } else if (status == 2) {
            return "#007680"
        } else if (status == 3) {
            return "#86BC25"
        } else if (status == 4) {
            return "#FF000080"
        }
    }

    const getUrl = (status) => {
        if (status == 1) {
            return "/Images/StandBy.svg"
        } else if (status == 2) {
            return "/Images/Loading.svg"
        } else if (status == 3) {
            return "/Images/Success.svg"
        } else if (status == 4) {
            return "/Images/Error.svg"
        }
    }

    return (
        <div className="advisor-wrapper">
            <div className="advisor-menu-wrapper">
                <button onClick={() => setExpanded(!expanded)} className={`invisible-button advisor-menu-button${!expanded ? " closed" : ""}`}>
                    <img src="/Images/Hamburger.svg" />
                </button>
                {
                    data.map((menuItem, index) => (
                        <div
                            onClick={() => setSelectedItem(index)}
                            key={index}
                            className={`menu-item-wrapper${selectedItem == index ? " selected" : ""}`}
                        >
                            {index + 1}

                            <div style={{ color: getColor(menuItem.status) }} className={`menu-item-title${selectedItem == index ? " selected" : ""}`}>
                                <img className='menu-item-icon' src={getUrl(menuItem.status)} />
                                {expanded && <>{menuItem.title}</>}
                            </div>
                        </div>
                    ))
                }
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {data[selectedItem].component}
                {selectedItem > 0 &&
                    <ChatWithRobotButton
                        fetchChatHistory={() => getChatHistory(1, 1, currentChatId)}
                        sendChatbotQuestion={(p, b) => questionChat(1, 1, { chat_id: currentChatId, question: b.question })}
                    />
                }
                {selectedItem < data.length - 1 && <div className='next-section-button-wrapper'>
                    <button style={{ marginTop: "10px", marginBottom: "2rem" }} className='next-section-button' onClick={() => handleNextClick()}>
                        Go to next section &#8594;
                    </button>
                </div>}
            </div>
        </div>
    )
}

export default AdvisorWrapper
