import React from 'react';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'; // Adjust the path as necessary
import './Pointsinformation.css';
import { useMyGlobalContext } from '../../global/globalContext'
import toast from 'react-hot-toast';

const PointsInformation = ({
    title,
    points,
    setPoints,
    deleteContextFinding,
    updateKeyFinding,
    generateKeyFinding,
    deleteAllKeyFindings

}) => {
    const [editingIndex, setEditingIndex] = React.useState(null);
    const [originalPoint, setOriginalPoint] = React.useState(null); // Store the original point data
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showConfirmModal1, setShowConfirmModal1] = React.useState(false);
    const [showConfirmModal2, setShowConfirmModal2] = React.useState(false);

    const [deleteIndex, setDeleteIndex] = React.useState(null); // To track which item is being deleted
    const { globalState } = useMyGlobalContext()
    const [pointId, setPointId] = React.useState(null);

    const toggleEdit = (index) => {
        if (editingIndex === index) {
            setEditingIndex(null); // Exiting edit mode
            setOriginalPoint(null); // Clearing the original point data
        } else {
            setEditingIndex(index);
            setOriginalPoint({ ...points[index] }); // Storing a copy of the original point data
        }
    };

    const handlePointChange = (index, field, value) => {
        const newPoints = [...points];
        newPoints[index][field] = value;
        setPoints(newPoints);
    };

    const handleSave = async () => {
        if (editingIndex === null) return; // Ensure there is an editing index

        try {
            const point = points[editingIndex]; // Get the currently editing point
            const updatedPoint = {
                ...point,
                Description: point.Description, // Assuming the Description is already updated in the points state
                MigrationImpact: point.MigrationImpact, // Assuming the MigrationImpact is already updated in the points state
            };

            // Call the API with the updated point data
            const response = await updateKeyFinding(globalState.projectId, globalState.assessmentId, updatedPoint.id, updatedPoint);

            toast.success("Key finding has been successfully updated");

            setEditingIndex(null); // Exit edit mode
            setOriginalPoint(null); // Clearing the original point data
        } catch (error) {
            console.error(error);
            toast.error("Error occurred while trying to update the key finding.");
        }
    };

    const handleDelete = (index, id) => {
        setDeleteIndex(index);
        setPointId(id);
        setShowConfirmModal(true);
    };

    const handleCancel = () => {
        const newPoints = [...points];
        if (originalPoint && editingIndex !== null) {
            newPoints[editingIndex] = originalPoint; // Revert to original point
            setPoints(newPoints);
        }
        setEditingIndex(null); // Exit edit mode
        setOriginalPoint(null); // Clearing the original point data
    };

    const confirmDelete = async () => {
        try {
            const newPoints = points.filter((_, index) => index !== deleteIndex);
            setPoints(newPoints);
            const response = await deleteContextFinding(globalState.projectId, globalState.assessmentId, pointId)
            toast.success("Key finding has been successfully deleted");

            setShowConfirmModal(false);
        } catch (error) {
            console.error(error);
            toast.error("Error occurred while trying to delete the key finding.");
        }
    };


    const handleDeleteAll = async () => {
        setShowConfirmModal2(true); // This should trigger the modal to confirm generation
    };

    const confirmDeleteAll = async () => {
        try {
            const response = await deleteAllKeyFindings(globalState.projectId, globalState.assessmentId)


            toast.success("All key findinds are deleted");

        }
        catch (error) {
            console.error(error);
            toast.error("Error occurred while create key findings.");
        }

        setShowConfirmModal2(false);

    };


    const handleGenerate = async () => {
        setShowConfirmModal1(true); // This should trigger the modal to confirm generation
    };

    const confirmGenerate = async () => {
        // Template for a new key finding
        try {
            const response = await generateKeyFinding(globalState.projectId, globalState.assessmentId)
            const newKeyFinding = response.data

            // Append the new key finding to the current list of points
            const newPoints = [...points, newKeyFinding];
            setPoints(newPoints);
            toast.success("New key findings will appear shortly");

        }
        catch (error) {
            console.error(error);
            toast.error("Error occurred while create key findings.");
        }
        // Optionally close the confirmation modal if you want
        setShowConfirmModal1(false);

    };


    return (
        <div className='upload-form-wrapper'>
            {title && <div className='upload-form-title-wrapper1'><h2>{title}</h2></div>}
            <div className="points-table-wrapper"> {/* Wrapper for styling */}
                <table className={`points-table ${!title ? "full" : ""}`}>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Migration Impact</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {points.map((point, index) => (
                            <tr key={index}>
                                <td>{point.Title}</td>
                                <td>{editingIndex === index ? <textarea value={point.Description} onChange={(e) => handlePointChange(index, 'Description', e.target.value)} rows={4} /> : point.Description}</td>
                                <td>{editingIndex === index ? <textarea value={point.MigrationImpact} onChange={(e) => handlePointChange(index, 'MigrationImpact', e.target.value)} rows={4} /> : point.MigrationImpact}</td>
                                <td>
                                    {editingIndex === index ? (
                                        <div className="actions" style={{ justifyContent: 'center' }}>
                                            <button onClick={handleSave} className="action-button save-button"><i className="fa fa-save"></i></button>
                                            <button onClick={handleCancel} className="action-button cancel-button">Cancel</button>
                                        </div>
                                    ) : (
                                        <div className="actions">
                                            <button onClick={() => toggleEdit(index)} className="action-button edit-button"><i className="fa fa-edit"></i></button>
                                            <button onClick={() => handleDelete(index, point.id)} className="action-button delete-button"><i className="fa fa-trash"></i></button>
                                            <button onClick={() => console.log()} className="action-button sync-button"><i className="fa fa-sync"></i></button>
                                        </div>
                                    )}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                    <tfoot style={{ backgroundColor: "#f5f5f5" }}>

                        <td colSpan="100%"> {/* Adjust this colspan value to match your actual number of columns */}
                            <div className="table-end-actions" style={{ justifyContent: 'right' }}>
                                <button onClick={() => handleGenerate()} className="save-btn">Generate Key Findings</button>
                                <button onClick={() => handleDeleteAll()} className="save-btn">Delete all</button>

                            </div>

                        </td>

                    </tfoot>
                </table>

            </div>
            <ConfirmModal
                show={showConfirmModal}
                setShow={setShowConfirmModal}
                title="Confirm Delete"
                content="Are you sure you want to delete this key finding?"
                confirmFunc={confirmDelete}
                rejectFunc={() => setShowConfirmModal(false)}
            />
            <ConfirmModal
                show={showConfirmModal1}
                setShow={setShowConfirmModal1}
                title="Confirm Generate"
                content="Are you sure you want to generate a new key finding?"
                confirmFunc={confirmGenerate} // This should call confirmGenerate now, not confirmDelete
                rejectFunc={() => setShowConfirmModal1(false)}
            />
            <ConfirmModal
                show={showConfirmModal2}
                setShow={setShowConfirmModal2}
                title="Confirm Delete all"
                content="Are you sure you want to delete all the key findings?"
                confirmFunc={confirmDeleteAll} // This should call confirmGenerate now, not confirmDelete
                rejectFunc={() => setShowConfirmModal2(false)}
            />
        </div>
    );

};

export default PointsInformation;
