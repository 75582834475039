import React from 'react';
import './DisplayVisualClusters.css'
import OpenTextBox from '../OpenTextBox/OpenTextBox';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';
import toast from 'react-hot-toast';
import { useMyGlobalContext } from '../../global/globalContext'
import { generateMigrateCriticality, deleteSoftware, generateEndOfLife, putVirtualCluster, deleteServer, putServer } from '../../services/CloudMigrationAdvisor/InfrastructureAssessment';
import { useState } from 'react';

const DisplayServers = ({
    title,
    content,
    tableHeader,
    tableData,
    message,
    setMessage,
    pillTitle,
    update,
    pillContent,
    graphUrl,
    component,
    generateValueFromAPI
}) => {






    const [originalPoint, setOriginalPoint] = React.useState(null); // Store the original point data
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showConfirmModal1, setShowConfirmModal1] = React.useState(false);
    const [showConfirmModal2, setShowConfirmModal2] = React.useState(false);
    const [showConfirmModal3, setShowConfirmModal3] = React.useState(false);


    const { globalState } = useMyGlobalContext()
    const [pointId, setPointId] = React.useState(null);

    const [editingIndex, setEditingIndex] = React.useState(null);
    const [originalData, setOriginalData] = React.useState(null);
    const [showGenerateModal, setShowGenerateModal] = React.useState(false);
    const [showDeleteAllModal, setShowDeleteAllModal] = React.useState(false);
    const [deleteIndex, setDeleteIndex] = React.useState(null);
    const [dataId, setDataId] = React.useState(null);
    const [dataPoints, setDataPoints] = React.useState(tableData); // Assuming tableData is the initial state
    const [MigrationID, setMigrationID] = React.useState(null);
    const [migrationContent, setMigrationContent] = React.useState(null);
    const [softwareID, setSoftwareID] = React.useState(null);
    const [softwareID2, setSoftwareID2] = React.useState(null);
    const [editedRow, setEditedRow] = useState([]);
    const [editId, setEditId] = useState([]);

    // Handler and toggle functions...
    // Since the functions are quite specific, I'll focus on major name changes to reflect their revised purposes
    // Assuming your code logic is correct and only focusing on renaming based on your request
    // Other state and variables are the same...
    const [isEditing, setIsEditing] = React.useState(null); // Track the index of the row being edited

    const toggleEdit = (index, id) => {
        setEditId(id)
        setEditingIndex(index);
        setEditedRow([...dataPoints[index]]); // Copy the current row data to editedRow for editing
    };
    const handleCellChange = (cellIndex, value) => {
        let updatedRow = [...editedRow]; // Create a shallow copy of the current editedRow
        updatedRow[cellIndex] = value; // Update the value at the specific cell index
        setEditedRow(updatedRow); // Update the editedRow state with the new array
    };

    const saveRow = async () => {
        try {
            // Assuming editId corresponds to a unique identifier for the row, and not just the index
            const point = [...editedRow];

            const updatedDataPoint = {
                // Assuming the structure of your data point. You might need to adjust it according to your actual data structure
                // For example, if your data structure includes an 'id', 'name', 'version', etc., you should construct the object accordingly
                // id: point[0], // Just as an example, if the first element of the row is the id
                Name: point[1],
                Operating_System: point[2],
                CPU: point[3],
                RAM: point[4],
                Storage: point[5],
                Type: point[6],
                VirtualizationCluster: point[7],
                Cluster: point[8],




            };

            // API call to update the data on the server
            const response = await putServer(globalState.projectId, globalState.assessmentId, editId, updatedDataPoint); // Assuming editId is the id of the data point to update


            // Update the local state with the updated item
            let newDataPoints = [...dataPoints];
            const updateIndex = newDataPoints.findIndex(item => item[0] === editId); // Find the index of the item to update by id
            newDataPoints[updateIndex] = [editId, ...Object.values(updatedDataPoint)]; // Update the row in the array

            setDataPoints(newDataPoints); // Set the updated array as the new dataPoints state
            setEditingIndex(null); // Exit edit mode
            toast.success("Saved successfully");
            setEditedRow([]); // Clear edited row
        } catch (error) {
            console.error(error);
            toast.error("Number of Installations must be a number.");
        }
    };

    // Cancel editing and reset the editing state without saving changes
    const cancelEdit = () => {
        setEditingIndex(null);
        setEditedRow({});
    };
    const handleDataChange = (index, field, value) => {
        const updatedDataPoints = dataPoints.map((item, idx) => {
            if (idx === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setDataPoints(updatedDataPoints);
    };


    const handleDelete = (index, id) => {
        setShowConfirmModal2(true);
        setDeleteIndex(index);
        setSoftwareID(id)
    };


    const confirmDelete = async () => {
        try {
            console.log(deleteIndex)
            const newPoints = dataPoints.filter((_, index) => index !== deleteIndex);
            setDataPoints(newPoints);
            const response = await deleteServer(globalState.projectId, globalState.assessmentId, softwareID);
            toast.success("This Software has been successfully deleted");
            setShowConfirmModal2(false);
        } catch (error) {
            console.log(error);
            toast.error("Failed to delete this Software");

        }
    };








    return (
        <div className="fault-tolerance-analysis1">
            <h1>{title}</h1>
            <p>{content}</p>
            {(pillTitle || pillContent) && (
                <div className="pill">
                    {pillTitle && <span className="pill-title">{pillTitle}</span>}
                    {pillContent && <span className="pill-content">{pillContent}</span>}
                </div>
            )}

            {!component ? (
                <div className='tablegraphContainer'>
                    <table>
                        <thead>
                            <tr>
                                {tableHeader.filter(header => header !== 'Migration Criticality').map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                                {tableHeader.includes('Migration Criticality') && <th key="actions">Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {dataPoints.map((row, index) => {
                                if (!Array.isArray(row)) {
                                    console.error('Expected row to be an array, but got:', typeof row, row);
                                    return null; // or handle this case differently
                                }


                                // Assume you have a state variable `editingIndex` to track the index of the row being edited
                                const isEditing = editingIndex === index;

                                return (
                                    <tr key={index}>
                                        {row.slice(1).map((cell, cellIndex) => {
                                            const isEditableField = tableHeader[cellIndex] === 'Name' || tableHeader[cellIndex] === 'Operating_System' || tableHeader[cellIndex] === 'CPU' || tableHeader[cellIndex] === 'RAM' || tableHeader[cellIndex] === 'Storage' || tableHeader[cellIndex] === 'Type' || tableHeader[cellIndex] === 'VirtualizationCluster' || tableHeader[cellIndex] === 'Cluster';

                                            // Check if the current field is 'Operating_System' for setting width
                                            let inputWidth;
                                            if (tableHeader[cellIndex] === 'Name') {
                                                inputWidth = "100px"; // Width for 'Name'
                                            } else if (tableHeader[cellIndex] === 'Operating_System') {
                                                inputWidth = "230px"; // Width for 'Operating_System'
                                            } else if (tableHeader[cellIndex] === 'VirtualizationCluster') {
                                                inputWidth = "120px"; // Width for 'Operating_System'
                                            } else {
                                                inputWidth = "80px"; // Default width
                                            }
                                            // Render a cell with an input field if in edit mode and is an editable field
                                            if (isEditing && isEditableField) {
                                                return (
                                                    <td key={cellIndex}>
                                                        <input
                                                            style={{ width: inputWidth }} // Use the determined width

                                                            type="text"
                                                            value={editedRow[cellIndex + 1]}
                                                            onChange={(e) => handleCellChange(cellIndex + 1, e.target.value)}
                                                            className="editable-input"
                                                        />
                                                    </td>

                                                );
                                            }


                                            // Render the cell normally if not in edit mode
                                            return <td key={cellIndex}>{cell}</td>;
                                        })}

                                        {/* Action buttons */}
                                        <td key="actions-cell">
                                            <div className='btns-vertical'>
                                                {isEditing ? (
                                                    <>
                                                        <button onClick={() => saveRow(index)} className="save-btn">Save</button>
                                                        <button onClick={() => cancelEdit()} className="save-btn">Cancel</button>
                                                    </>
                                                ) : (
                                                    <>


                                                        <button onClick={() => toggleEdit(index, row[0])} className="save-btn"><i className="fa fa-edit"></i></button>
                                                        <button onClick={() => handleDelete(index, row[0])} className="save-btn"><i className="fa fa-trash"></i></button>


                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>

                    </table>

                    {graphUrl && (
                        <div className="graph-container">
                            <img src={graphUrl} alt="Graph" className="graph-image" />
                        </div>
                    )}
                </div>
            ) : (
                <>{component}</>
            )}
            <div style={{ backgroundColor: 'white', width: '100%', paddingBottom: '1.4rem' }}>
                {message && (
                    <OpenTextBox
                        title="Description"
                        value={message}
                        setValue={setMessage}
                        updateButton={update}
                        generateValueFromAPI={generateValueFromAPI}
                    />
                )}



                <ConfirmModal
                    show={showConfirmModal2}
                    setShow={setShowConfirmModal2}
                    title="Confirm Delete field"
                    content="Are you sure you want to delete this Server?"
                    confirmFunc={confirmDelete}
                    rejectFunc={() => setShowConfirmModal2(false)}
                />

            </div>
        </div>
    );
};
export default DisplayServers;
