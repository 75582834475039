import React, { useRef, useState, useEffect } from 'react'
import './UploadFormCMA.css'

const UploadFormCMA = ({
    title,
    info,
    downloadable = false,
    confirmUpload = true,
    UploadTepmlate = false,
    showFiles = true,
    downloadUrl = "",
    handleUpload = async () => { },
    handleUploadTemplate = async () => { },
    handleRemoveFiles = async () => { }
}) => {
    const uploadRef = useRef()
    const uploadRef2 = useRef()

    const [fileNames, setFileNames] = useState([])
    const [filesToUpload, setFilesToUpload] = useState([])
    const [pendingConfirmation, setPendingConfirmation] = useState(false)

    const handleDownload = () => {
        if (downloadUrl == "") return
        // Create a temporary anchor element
        const anchorElement = document.createElement('a');
        anchorElement.href = downloadUrl;
        // anchorElement.download = 'template.'; // Specify the desired file name

        // Append the anchor element to the document
        document.body.appendChild(anchorElement);

        // Trigger a click event on the anchor element to start the download
        anchorElement.click();

        // Remove the anchor element from the document
        document.body.removeChild(anchorElement);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return
        if (confirmUpload) {
            setPendingConfirmation(true)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([...filesToUpload, file])
        } else {
            //Upload file
            // const formData = new FormData()
            // formData.append(formDataFileName, file)
            await handleUpload(file)
            setPendingConfirmation(false)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([])
        }
    }

    const handleFileTemplateChange = async (event) => {



        //NEEDS FIX , IT DOES THE SAME AS UPLOAD DOCUMENT



        const file = event.target.files[0];
        if (!file) return
        if (confirmUpload) {
            setPendingConfirmation(true)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([...filesToUpload, file])
        } else {
            //Upload file
            await handleUploadTemplate(file)
            setPendingConfirmation(false)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([])
        }
    }

    const handleUploadClick = () => {
        if (!uploadRef) return
        uploadRef.current.click()
    }

    const handleUploadTemplateClick = () => {
        if (!uploadRef2) return
        uploadRef2.current.click()
    }


    const handleConfirmClick = async () => {
        try {
            //Upload file
            const formData = new FormData()
            filesToUpload.forEach(file => {
                formData.append('file', file);
            });
            await handleUpload(formData)
            setPendingConfirmation(false)
            setFilesToUpload([])
        } catch (error) {
            console.log(error)
        }

    }

    const removeFile = (indexToRemove) => {
        console.log("removing file")
        handleRemoveFiles()
        setFileNames([])
        // setFileNames([...fileNames.slice(0,indexToRemove),...fileNames.slice(indexToRemove+1)])
    }

    return (
        <>
            <div className='upload-form-wrapper'>
                <div className='upload-form-title-wrapper'>
                    {title}
                    <div className='upload-form-button-wrapper'>
                        {downloadable &&
                            <button onClick={() => handleDownload()} className='download-form-button'>
                                Download template
                                <img src="../../../Images/DownloadTemplate.svg" alt="DownloadTemplate" style={{ marginBottom: '5px', marginLeft: '4px' }} />
                            </button>
                        }
                        {UploadTepmlate &&
                            <button onClick={() => handleUploadTemplateClick()} className='download-form-button'>
                                Upload Template
                                <img src="../../../Images/UploadTemplate.svg" alt="UploadTemplate" style={{ marginBottom: '3px', marginLeft: '2px' }} />
                            </button>
                        }
                        <button onClick={() => handleUploadClick()} className='upload-form-button'>
                            Upload documents
                            <img src="../../../Images/UploadDocuments.svg" alt="UploadDocunent" style={{ marginBottom: '3px', marginLeft: '4px' }} />
                        </button>

                    </div>
                </div>
                <div className='upload-form-body-wrapper'>
                    {info}
                    {
                        fileNames.length > 0 &&
                        <div className='uploaded-files'>
                            {showFiles && fileNames.map((name, index) => {
                                return (
                                    <div key={index} className='uploaded-file-pill'>
                                        {name}
                                        <img src="/Images/Delete.svg" className='pill-button' onClick={() => removeFile(index)} />
                                    </div>)
                            })
                            }
                        </div>
                    }
                </div>
            </div>
            <input onChange={(event) => handleFileChange(event)} ref={uploadRef} className='upload-input' type="file" id="file-upload" name="file-upload" />
            <input onChange={(event) => handleFileTemplateChange(event)} ref={uploadRef2} className='upload-input' type="file" id="file-upload" name="file-upload" />

        </>
    )
}

export default UploadFormCMA