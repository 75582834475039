import React from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useToken from '../components/App/useToken';
import { useNavigate } from 'react-router-dom';
import useCurrentProject from '../components/App/useCurrentProject';
import { useState, useEffect } from 'react';
import ProjectModal from '../components/Projects/ProjectModal';
import { getProject } from '../services/projectService';
import { useMyGlobalContext } from "../global/globalContext"

function Layout() {
  const { currentProject } = useCurrentProject();
  const { setToken } = useToken();
  const navigate = useNavigate();
  const { globalState, updateGlobalState } = useMyGlobalContext()
  const [ProjectModalShow, setProjectModalShow] = useState(false);

  const handleProjectModalClose = () => setProjectModalShow(false);
  const handleProjectModalShow = () => setProjectModalShow(true);



  const [newProject, setNewProject] = useState([]);
  const init = async () => {
    const projectList = await getProject(currentProject);
    if (currentProject) updateGlobalState({ ...globalState, projectId: currentProject })
    console.log("aaaaaaaaaaaaa", projectList, currentProject)
    setNewProject(projectList.data.name);
  }


  useEffect(() => {
    init();
  }, []);

  return (
    <div className="app">
      {/* Main */}
      <div className="navigation-bar">
        {/* Top Menu Bar */}
        <h2 className="deloitte-header"><img src="https://www2.deloitte.com/content/dam/assets/logos/deloitte.svg" className="deloitteLogo" /> &nbsp;Generative AI-DesignSuite</h2>

        <Navbar collapseOnSelect expand="lg" className="main-navbar">
          <Navbar.Brand className="color-navItem navHover" href="/">Home</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link className="color-navItem navHover" href={`/projects/${currentProject}/application-build`}>Software Lifecycle Advisor</Nav.Link> */}
              {/* <Nav.Link className="color-navItem navHover" href="/cloud-suitability-assessment">Cloud Suitability Assessment</Nav.Link> */}
              <Nav.Link className="color-navItem navHover" href="/it-assessment">Cloud Migration Advisor</Nav.Link>
              {/* <Nav.Link className="color-navItem navHover" href="/cloud-suitability-assessment">Deloitte Knowledge Navigator</Nav.Link> */}
            </Nav>
            <Nav>
              <Navbar.Text className="project-modal navHover color-navItem" onClick={handleProjectModalShow}> Active Project: {newProject}</Navbar.Text>
              <ProjectModal modalShow={ProjectModalShow} handleModalClose={handleProjectModalClose} />

              <Nav className="me-auto">
                <Nav.Link className="color-navItem navHover" style={{ marginTop: "5px" }} href="/" onClick={() => { setToken({ access: null, refresh: null }); window.location.reload() }} >Logout</Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </div>

      <Outlet />



      {/* Add simple green bar */}
      {/* <div className="simple-green-bar">
      </div> */}
    </div>


  );
}

export default Layout;
