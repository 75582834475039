import React, { useState, useEffect } from 'react';
import './SimpleTable.css'
const SimpleTable = ({ headers, data, selectable=false, selectRowFunc }) => {
    const [tableHeaders, setTableHeaders] = useState(headers);
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        setTableHeaders(headers);
        setTableData(data);
    }, [headers, data]);

    return (

        <table>
            <thead>
                <tr>
                    {tableHeaders.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className={selectable?"selectable":""} onClick={()=>{console.log(rowIndex);selectRowFunc(row, rowIndex)}}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SimpleTable;
