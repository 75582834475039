import { API } from '../api'

const getApplications = (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/`);
const getApplicationCard = (projectId, assessmentId, applicationId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/${applicationId}/applicationcard/`);

const getApplicationsFindings = (projectId, assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/criticalpoint/`);

const generateApplicationSummary = async (projectId, assessmentId) => API().post(`/project/${projectId}/assessment/${assessmentId}/applicationcontext/resume/generate/`)
export {
    getApplications,
    getApplicationCard,
    getApplicationsFindings,
    generateApplicationSummary
};