import React from 'react';
import './App.css';
import Router from './Router';
import useToken from './components/App/useToken';
import Login from './pages/Login/Login';
import { Toaster } from 'react-hot-toast';
import { MyGlobalContextProvider } from './global/globalContext';

function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />
  }

  console.log("token", token);

  return (
    <MyGlobalContextProvider>
      <div className="App">
        <Router />
        <Toaster />
      </div>
    </MyGlobalContextProvider>
  );
}

export default App;
