import React, { useState, useEffect } from 'react';
import './OpenTextBox.css';
import toast from 'react-hot-toast';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';

const OpenTextBox = ({
    title,
    subtitle,
    value,
    setValue,
    updateButton,
    generateValueFromAPI // API function to generate new value
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [originalValue, setOriginalValue] = useState(value); // State to store the original value

    const toggleEdit = () => {
        setOriginalValue(value); // Store the current value as the original value
        setIsEditing(true); // Enter edit mode
    };

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);

    const handleGenerate = async () => {
        setShowConfirmModal(true);
    };

    const confirmGenerate = async () => {
        try {
            const newValue = await generateValueFromAPI(); // Call the API to get new valueSS
            setValue(newValue); // Set the new value
            setShowConfirmModal(false);
            toast.success("A new Summary will be generated shortly");

        } catch (error) {
            console.error(error);
            toast.error("Error occurred while trying to generate the new value.");
        }
    };

    const handleCancel = () => {
        setValue(originalValue); // Revert to the original value
        setIsEditing(false); // Exit edit mode
    };

    const handleSave = () => {
        if (updateButton) {
            updateButton(); // Call the update function
            toast.success("Saved successfully"); // Show success toast
            setIsEditing(false); // Exit edit mode
        }
    };

    return (
        <div className='upload-form-wrapper'>
            {title && (
                <div className='upload-form-title-wrapper1'>
                    <h2>{title}</h2>
                </div>
            )}
            <div className={`body-wrapper ${!title ? "full" : ""}`}>
                {subtitle}
                <div className='form-text-area'>
                    {!isEditing ? (
                        <div className="static-text">{value}</div>
                    ) : (
                        <textarea
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    )}
                </div>
                <div className='btn-pos'>
                    {isEditing && (
                        <>
                            <button className='save-btn' onClick={handleSave}>
                                Save
                            </button>
                            <button className='save-btn' onClick={handleCancel}>
                                Cancel
                            </button>
                        </>
                    )}
                    {!isEditing && (
                        <button className='save-btn' onClick={toggleEdit}>
                            Edit
                        </button>
                    )}

                    <button className='save-btn' onClick={handleGenerate}>
                        Generate
                    </button>


                </div>
            </div>
            <ConfirmModal
                show={showConfirmModal}
                setShow={setShowConfirmModal}
                title="Confirm Generate"
                content="Are you sure you want to generate a new Summary?"
                confirmFunc={confirmGenerate}
                rejectFunc={() => setShowConfirmModal(false)}
            />
        </div >
    );
};

export default OpenTextBox;
