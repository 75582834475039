import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./ChatWithRobot.css"; // Make sure the CSS path is correct for this component
import { MdOutlineArrowUpward } from "react-icons/md";
import toast from "react-hot-toast";
import { useMyGlobalContext } from "../../global/globalContext";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import { MdPerson } from "react-icons/md";
import { BsRobot } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

const ChatWithRobot = ({
    fetchChatHistory,
    sendChatbotQuestion,
    regen = true,
    responseTitle = "CMA",
    redirectable = true
}) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const { globalState } = useMyGlobalContext();
    const [user, setUser] = useState("");
    const navigate = useNavigate();
    const textareaRef = useRef(null);

    const handleTextareaResize = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${scrollHeight}px`;
        }
    };
    
    useEffect(() => {
        handleTextareaResize();
    }, [newMessage]);

    const handleCopyCode = (content) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                toast.success('copied to clipboard')
            })
            .catch((error) => {
                console.error('Failed to copy code to clipboard:', error);
                toast.error('Failed to copy code to clipboard. Please try again.');
            });
    };
    
    const parseMessageContent = (content) => {
        const parts = [];
        let currentPart = '';
        let inCodeBlock = false;
        let currentLanguage = '';
    
        const lines = content.split('\n');
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            if (line.startsWith('```')) {
                if (!inCodeBlock) {
                    inCodeBlock = true;
                    const languageMatch = line.match(/```(.+)/);
                    currentLanguage = languageMatch ? languageMatch[1].trim() : '';
                } else {
                    inCodeBlock = false;
                    parts.push({ type: 'code', content: currentPart.trim(), language: currentLanguage });
                    currentPart = '';
                    currentLanguage = '';
                }
            } else {
                if (inCodeBlock) {
                    currentPart += line + '\n';
                } else {
                    if (currentPart.trim().length > 0) {
                        parts.push({ type: 'text', content: currentPart });
                    }
                    currentPart = line + ' ';
                }
            }
        }
        if (currentPart.trim().length > 0) {
            parts.push({ type: inCodeBlock ? 'code' : 'text', content: currentPart.trim(), language: currentLanguage });
        }
    
        return parts;
    };
    
    
    

    useEffect(() => {
        const fetchChatHistoryData = async () => {
            try {
                const response = await fetchChatHistory(globalState.projectId, globalState.chatId);
                setChatHistory(response.data.chat.chat_history);
                setUser(response.data.chat.user);
            } catch (error) {
                console.error("Error fetching chat history:", error);
                toast.error("An error occurred while fetching the chat history.");
            }
        };
        if (!globalState.chatId && redirectable) {
            navigate("/cloud-suitability-assessment");
            return;
        }
        fetchChatHistoryData();
    }, [fetchChatHistory, globalState.chatId, globalState.projectId, navigate]);

    const handleSendMessage = async () => {
        if (newMessage.trim() !== "") {
            const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const updatedChatHistory = [...chatHistory, { role: "human", content: newMessage, timestamp: timestamp }];
            setChatHistory(updatedChatHistory);
            setNewMessage("");
            // While response is loading
            setChatHistory((oldChatHistory) => [
                ...oldChatHistory,
                { role: "ai", content: "..." },
            ]);
            const request = {
                chat_id: globalState.chatId,
                question: newMessage,
            };
            try {
                const response = await sendChatbotQuestion(globalState.projectId, request);
                const aiResponse = response.data.message;
                setChatHistory((oldChatHistory) => [
                    ...oldChatHistory.slice(0, oldChatHistory.length - 1),
                    { role: "ai", content: aiResponse },
                ]);
            } catch (error) {
                console.log(error);
                toast.error("An error occurred while sending question.");
            }
        }
    };


    return (
        <div className="chat-container2">

            <Row className="chat-messages1">
                <Col xs={12} className="chat-messages-container">
                    {chatHistory.map((message, index) => (
                        <div key={index} className="message-wrapper">
                            {message.role === "ai" ? (
                                <div className="RbtIcnWrapper"><BsRobot size ={20} style={{marginTop:'5px',color:'#2C5234'}} /></div>
                            ) : <div className="UsrIcnWrapper"><MdPerson size={20} style={{marginTop:'5px'}} /></div>}
                            
                            <div className={`message1 ${message.role === "human" ? "sent1" : "received1"}`}>
                               <span>
                               {message.role === "ai" ? <span className="message-label">DKN</span> : <span className="message-label">You</span>}
                               {parseMessageContent(message.content).map((part, partIndex) => (
                                part.type === 'code' ? (
                                <div className="code-wrapper">
                                    <div className="languageHeader">{part.language}<div className="codeCopy" onClick={() => handleCopyCode(part.content)} ><FaRegCopy size={17} />  Copy code </div></div>
                                <div key={partIndex} className="code-block">
                                    <pre>
                                        <code>{part.content}</code>
                                    </pre>
                                </div>
                                </div>
                            ) : (
                                <Markdown key={partIndex}>{part.content}</Markdown>
                            )
                        ))}
                            {message.role==="ai" && <FaRegCopy onClick={() => handleCopyCode(message.content)} className="bottomCopy" style={{marginTop:'-30px'}} />}
                            {/* <div className="timestamp">{message.timestamp}</div> */}
                               </span>
                            </div>
                        </div>
                    ))}

                </Col>
                </Row>

            <Row className="message-inp">
                <Col style={{display:'flex',justifyContent:'center'}}>
                    <div className="promptContainer">
                    <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your message..."
                        value={newMessage}
                        className="promptForm"
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                        ref={textareaRef}
                        onInput={handleTextareaResize}
                    />
                    <Button
                        onClick={handleSendMessage}
                        className={newMessage.length > 0 ? 'sendBtn' : 'disabledSendBtn' }
                        disabled = {newMessage.length === 0}
                    >
                        <MdOutlineArrowUpward style={{ fontSize: '22px',marginBottom:'10px',paddingRight:'2px' }} />
                    </Button>
                    {regen && <Button
                        // Assuming you want the same action for this button as well
                        style={{ backgroundColor: '#86BC25', color: 'white', width: '4rem', height: '3.5rem' }} // A slightly different color for distinction
                    >
                        <i class="fas fa-sync " style={{ fontSize: '1.5rem' }}></i>
                    </Button>}
                    </div>
                </Col>
            </Row>

        </div >
    );
};

export default ChatWithRobot;
